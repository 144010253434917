@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Robot Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-main;
  padding: 0;
}

button {
  -webkit-tap-highlight-color: transparent;
}

@keyframes sound {
  0% {
    opacity: 0.1;
    transform: scaleY(2);
  }

  100% {
    opacity: 0.2;
    transform: scaleY(6);
  }
}

.bars {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  gap: 1px;
  padding-left: 32px;
  padding-right: 32px;
}

.bar {
  width: 100%;
  display: inline-block;
  transform-origin: center;
  animation: sound 400ms -800ms linear infinite alternate;
}

@layer utilities {
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }

  .snap-center {
    scroll-snap-align: center;
  }

  .button--scale {
    transform: scale3d(0.97, 0.97, 1);
  }

  .snappable {
    scroll-snap-type: x mandatory;
    pointer-events: all;
  }
}
